import React from "react"
import Layout from "gatsby-theme-tailwindui/src/components/layout"
import { useSiteMetadata } from "gatsby-theme-tailwindui/src/utils/use-site-metadata"
import PDFLoader from "../components/PDFLoader/PDFLoader"

const PDFFile = (props) => {
  const { file } = props.pageContext
  const { globalNavMenu, globalContactMenu } = useSiteMetadata()
  const menuItems = globalNavMenu
  const contactMenu = globalContactMenu
  return (
    <Layout navMenuItems={menuItems} contactMenuItems={contactMenu}>
      <div>
        <h1>{file.title}</h1>
        {/* I had to get the div width via JS because PDF library doesnt support 100%, fluid width */}
        <PDFLoader pdf={file?.pdf?.pdfFile?.asset?.url} />
      </div>
    </Layout>
  )
}

export default PDFFile
